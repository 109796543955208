import React from 'react'
import { toast, Zoom, Bounce} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()

export function Toast(msg,type) {
  if(type==="success")
  {
    toast.success(msg,{
        draggable: true,
        hideProgressBar:true,
        transition: Zoom,
        autoClose:3000,
        position: toast.POSITION.TOP_CENTER
      })
  }
  else
  {
    toast.error(msg,{
      draggable: true,
      hideProgressBar:true,
      transition: Zoom,
      autoClose:3000,
      position: toast.POSITION.TOP_CENTER
    })
  }
}
import React, { useEffect, useState } from 'react';
import { Table, Input } from 'antd';
import moment from 'moment';
import Header from './Header/Header';
const { Search } = Input;

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    filterMode: 'tree',
    filterSearch: true,
    onFilter: (value, record) => record.name.startsWith(value),
    width: '20%',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '25%',
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Level',
    dataIndex: 'level',
    onFilter: (value, record) => record.address.startsWith(value),
    filterSearch: true,
    width: '15%',
  },
  {
    title: 'Image Link',
    dataIndex: 'imageLink',
    width: '20%',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: '20%',
    render: (date) => moment(date).format('DD-MM-YYYY hh:mm a'),
  },
];

const Analytics = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const FetchData = () => {
    fetch("/getData", {
      method: "get",
    })
    .then(res => res.json())
    .then(data => {
      setData(data);
      setFilteredData(data);  // Set initial filtered data
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });
  };

  // Filter data based on email search
  const handleSearch = (value) => {
    const filtered = data.filter((item) =>
      item.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchText(value);
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="table-container">
        <Search
          placeholder="Search by email"
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          style={{ marginBottom: 20, width: 300 }}
        />
        <Table columns={columns} dataSource={filteredData} />
      </div>
    </>
  );
};

export default Analytics;

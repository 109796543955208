import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate
} from "react-router-dom";
import Login from './components/Auth/Login';
import Analytics from './components/Analytics';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  var userARGames = JSON.parse(localStorage.getItem("UserSalesForce"));

  useEffect(() => {
    if (!userARGames && location.pathname !== '/') {
      // Redirect to login if not logged in and trying to access any other route
      navigate('/', { replace: true });
    }
  }, [userARGames, location, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route 
        path="/analytics" 
        element={userARGames ? <Analytics /> : <Navigate to="/" replace />} 
      />
    </Routes>
  );
}

export default App;

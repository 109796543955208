import React,{useState, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from "react-router-dom";
import {useNavigate} from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();

  const logout =() =>{
    localStorage.clear()
    navigate('/', {replace: true})
  }

  return (
    <Navbar collapseOnSelect expand="lg" className='header-border' variant="light">
        <Container fluid={true}>
          <Navbar.Brand className='header-logo'><img className='logo-img' src='images/Header-Logo.png'  /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className='header-link' onClick={()=>logout()}>Logout</Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
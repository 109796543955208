import React,{useEffect, useState} from 'react'
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { Toast } from '../Toast/Toast';
import {useNavigate, Link} from "react-router-dom";

export default function Login() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const onFinish = (values) => {
    console.log('Success:', values);
    const {email,password} = values;

    console.log('Success:', email,password);
    setLoading(true)
    fetch("/signin",{
      method:"post",
      headers:{
        "Accept":"application/json",
        "content-Type":"application/json"
      },
      body:JSON.stringify({
        email,
        password,
      })
  }).then(res=>res.json())
  .then(data=>
  {
      setLoading(false)
      Toast("Login successfully","success")
      localStorage.setItem("jwtUserSalesForce",data.token)
      localStorage.setItem("UserSalesForce",JSON.stringify(data.user))
      navigate('/analytics', {replace: true})
  })
  .catch((err)=>{
      console.log(err)
      setLoading(false)
      Toast("Invalid email","error")
  })

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if(loading)
  {
    return(
      <div className='spin-container'>
        <Spin size='large' />
      </div>
    )
  }
  else
  {
    return (
      <>
      <div className='form-container'>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className='form-sub-contatiner'
      >

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 0,
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit" className='submit-btn'>
            Login
          </Button>
          {/* <Link className='forgot-password' to='/forget-password'>Forgot Password?</Link>
          <div className='footer-auth'>Not a user? <span><Link className='footer-auth-reg' to='/register'>Register Here</Link></span> </div> */}
        </Form.Item>
      </Form>
      </div>
      </>
    );
  }
}